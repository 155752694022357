<template>
  <div class="home">
    <div class="discount-list">
      <ul>
        <li>
          <div class="left">
            <div class="name">
              专属折扣码
              <a-popover
                placement="bottomLeft"
                style="z-index: 98"
                trigger="click"
                v-model="visiable"
              >
                <template slot="content">
                  <div class="popbox">
                    <div
                      class="ptit"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span
                        class="num"
                        :class="{ rtl: lang == 'AR' ? true : false }"
                      ></span
                      ><span :class="[lang]">折扣码是否有使用次数?</span>
                    </div>
                    <div class="text">
                      是的，每个折扣码都有使用次数的限制，您可以在返佣管理列表折扣码基础信息处中查看剩余的次数。
                    </div>
                    <div
                      class="ptit mtop"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span
                        class="num"
                        :class="{ rtl: lang == 'AR' ? true : false }"
                      ></span
                      ><span :class="[lang]">折扣码是否有有效期??</span>
                    </div>

                    <div class="text">
                      是的，折扣码是有有效期的，您可以在返佣管理列表折扣码基础信息处查看有效期。
                    </div>

                    <div
                      class="ptit mtop"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span
                        class="num"
                        :class="{ rtl: lang == 'AR' ? true : false }"
                      ></span
                      ><span :class="[lang]">折扣码抵扣规则?</span>
                    </div>
                    <div class="text">
                      折扣码仅可用于首次支付时抵扣，后期续费时不再享受折扣码的折扣。
                    </div>

                    <div
                      class="ptit mtop"
                      :class="[lang]"
                      :style="{
                        'text-align': lang == 'AR' ? 'right' : 'left',
                      }"
                    >
                      <span
                        class="num"
                        :class="{ rtl: lang == 'AR' ? true : false }"
                      ></span
                      ><span :class="[lang]">返佣规则?</span>
                    </div>
                    <div class="text">
                      1.用户通过您的折扣码完成支付后，会进入到您的返佣列表。
                    </div>
                    <div class="text">
                      2.用户选择按月订阅，则佣金会在用户支付满30天后进行一次性发放，若30天内用户退款，则佣金不会发放。
                    </div>
                    <div class="text">
                      3.用户选择按年订阅，则会在用户支付满30天后进行第一次发放，后续每满30天进行一次发放，直至所有的佣金发放完毕若中间用户退款，则后续佣金不再发放。
                    </div>
                    <div
                      style="
                        font-weight: 400;
                        font-size: 14px;
                        color: #0e756a;
                        line-height: 30px;
                        margin-top: 34px;
                      "
                    >
                      以上解释权归strategybrain所有
                    </div>
                  </div>
                </template>

                <svg-icon
                  @click="visiable = true"
                  iconClass="help"
                  class="icon"
                ></svg-icon>
              </a-popover>
            </div>
            <div class="code">HD78IJ90</div>
            <div class="expirseTime">
              <span :class="[lang]">会员有效期:</span>
              <span class="time">2024-12-30</span>
            </div>
          </div>
          <div class="right">
            <dl>
              <dd>
                <div class="tit">折扣</div>
                <div class="numbers">
                  <span>8.5</span><span class="dis">折</span>
                </div>
              </dd>
              <dd class="line">
                <div class="tit">总次数</div>
                <div class="numbers"><span>30</span></div>
              </dd>
              <dd>
                <div class="tit">剩余次数</div>
                <div class="numbers"><span>26</span></div>
              </dd>
            </dl>
          </div>
        </li>
      </ul>
    </div>
    <div class="wrap-table">
      <div class="title title2">
        <div class="tit">
          <svg-icon iconClass="more2" class="icon"></svg-icon
          ><span>返佣管理</span>
        </div>
      </div>
      <a-table
        :scroll="{
          x: 1120,
        }"
        :locale="{ emptyText: $t('noCamp') }"
        size="middle"
        :columns="columns"
        :rowKey="(record, index) => index"
        :data-source="capinData"
        :pagination="false"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => goDetail(record),
              },
            };
          }
        "
        :loading="loading"
        :row-class-name="
          (_record, index) => (index % 2 !== 1 ? 'table-bg' : null)
        "
      >
        <template slot="status" slot-scope="text, record" class="arial">
          <div class="statusbox" @click.stop="">
            <span v-if="record.status == '1'">{{ $t("Active") }}</span>
            <span v-if="record.status == '0'">{{ $t("Paused") }}</span>
          </div>
          <div @click.stop="">
            <a-switch
              size="small"
              v-model="record.status"
              @change="onChangeRecord(record)"
            />
          </div>
        </template>

        <template slot="action" slot-scope="text, record" class="arial">
          <a-dropdown>
            <template #overlay>
              <a-menu @click.stop="handleMenuClick.bind(record)">
                <a-menu-item key="1" @click="goDetail(record)">
                  <a-icon type="edit" />
                  {{ $t("Detail") }}
                </a-menu-item>
                <a-menu-item key="2" @click="copyHandler(record)">
                  <a-icon type="copy" />
                  {{ $t("Copy") }}
                </a-menu-item>
                <a-menu-item key="3" @click="deleteHandler(record)">
                  <a-icon type="delete" />
                  {{ $t("Delete") }}
                </a-menu-item>
              </a-menu>
            </template>
            <a-button style="padding: 0 8px" @click.stop="">
              {{ $t("Action") }}
              <a-icon type="down" />
            </a-button>
          </a-dropdown>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visiable: false,
    };
  },
  computed: {
    columns: {
      get() {
        let that = this;
        return [
          {
            title: "ID",
            width: 120,
            dataIndex: "campaign_name",
            align: "center",
            fixed: "left",
          },
          {
            title: that.$t("订阅日期"),
            width: 160,
            dataIndex: "status",
            align: "center",
            fixed: "left",
            scopedSlots: {
              customRender: "status",
            },
          },
          {
            title: that.$t("返佣日期"),
            width: 120,
            dataIndex: "linkedin_email",
            align: "center",
          },
          {
            title: that.$t("订阅版本"),
            width: 120,
            dataIndex: "days",
            align: "center",
          },
          {
            title: that.$t("订阅周期"),
            width: 120,
            dataIndex: "customer_find",
            align: "center",
          },
          {
            title: that.$t("原价"),
            width: 120,
            dataIndex: "added",
            align: "center",
          },
          {
            title: that.$t("优惠价格"),
            width: 120,
            dataIndex: "accept_rate",
            align: "center",
            customRender: function (text) {
              return text ? text + "%" : "0%";
            },
          },

          {
            title: that.$t("实付价格"),
            width: 120,
            dataIndex: "replies",
            align: "center",
          },
          {
            title: that.$t("是否返佣"),
            width: 120,
            dataIndex: "replies_rate",
            align: "center",
            customRender: function (text) {
              return text == 1
                ? `<span style='color:#000000'>是</span>`
                : `<span style='color:#F4F5F8'>否</span>`;
            },
          },

          {
            title: that.$t("应返佣金"),
            width: 120,
            dataIndex: "leads",
            align: "center",
            customRender: function (text) {
              return "$" + (text ? text : "0");
            },
          },
          {
            title: that.$t("已返佣金"),
            width: 120,
            dataIndex: "rate",
            align: "center",
            customRender: function (text) {
              return "$" + (text ? text : "0");
            },
          },
          {
            title: that.$t("待返佣金"),
            align: "center",
            // fixed: "right",
            width: 120,
            customRender: function (text) {
              return "$" + (text ? text : "0");
            },
          },
        ];
      },
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  margin: 30px 30px 30px 30px;
}
.discount-list {
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    width: 100%;
    height: 160px;
    background: url(../../../assets/img/discount_bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    .left {
      margin-left: 200px;
      margin-top: 33px;
      .icon {
        margin-left: 6px;
      }
      .name {
        font-weight: bold;
        font-size: 18px;
        color: #775a4c;
        height: 24px;
        line-height: 24px;
      }
      .code {
        font-weight: bold;
        font-size: 36px;
        color: #4a352b;
        height: 47px;
        line-height: 47px;
      }
      .expirseTime {
        padding-top: 8px;
        font-weight: bold;
        font-size: 14px;
        color: #84571f;
      }
    }
    .right {
      width: 461px;
      height: 100px;
      margin-left: 220px;
      margin-top: 30px;
      background: url(../../../assets/img/bg1.png) no-repeat;
      border-radius: 4px 4px 4px 4px;

      dl {
        display: flex;
        align-items: center;
        dd {
          flex: 1;
          text-align: center;
          .tit {
            margin-top: 19px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: bold;
            font-size: 14px;
            color: #926752;
          }
          .numbers {
            margin-top: 10px;
            font-weight: bold;
            height: 33px;
            line-height: 33px;
            font-size: 30px;
            color: #684b3e;
            .dis {
              font-weight: bold;
              font-size: 12px;
              color: #684b3e;
            }
          }
        }
        .line {
          position: relative;
        }
        .line::after {
          position: absolute;
          content: "";
          display: inline-block;
          width: 1px;
          height: 36px;
          background: #a57436;
          right: 1px;
          top: 30px;
        }
        .line::before {
          position: absolute;
          content: "";
          display: inline-block;
          width: 1px;
          height: 36px;
          background: #a57436;
          left: 1px;
          top: 30px;
        }
      }
    }
  }
}
.wrap-table {
  padding: 0px 20px 12px 20px;
  background: #fff;
  border-radius: 4px;
  margin-top: 20px;
  .title {
    padding: 20px 0 14px 0;

    .tit {
      display: flex;
      align-items: center;
    }
    .icon {
      color: #1c746b;
      font-size: 20px;
    }
    span {
      font-family: Arial, Arial;
      font-weight: 400;
      font-size: 20px;
      color: #000000;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  .title2 {
    display: flex;
    justify-content: space-between;
    .campaignsDetail {
      font-weight: 400;
      font-size: 14px;
      color: #0e756a;
      cursor: pointer;
      .ir {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
}
.popbox {
  width: 530px;
  .ptit {
    font-weight: bold;
    font-size: 16px;
    color: #1c7a70;
    display: flex;
    align-items: center;
    .num {
      width: 6px;
      height: 6px;
      background: #1c7a70;
      margin-right: 10px;
    }
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    color: #646794;
    line-height: 24px;
    margin-top: 16px;
  }
  .mtop {
    margin-top: 37px;
  }
  .m16 {
    margin-bottom: 16px;
  }
}
</style>
